import Axios from "axios";
import axios from "../axios";
export const extractDate = (e) => {
  if (e) {
    let date = new Date(e).toLocaleDateString();
    return date;
  } else {
    return "";
  }
};
export async function handleImageUpload(file) {
  const formData = new FormData();
  formData.append("file", file);
  // Determine the type based on the file extension
  const fileExtension = file.name.split('.').pop().toLowerCase();

  let typeValue;
  switch (fileExtension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      typeValue = 1; // Image
      break;
    case "mp4":
    case "mkv":
    case "avi":
      typeValue = 2; // Video
      break;
    case "mp3":
    case "wav":
    case "aac":
      typeValue = 3; // Audio
      break;
    case "pdf":
      typeValue = 4; // PDF
      break;
    case "doc":
    case "docx":
      typeValue = 5; // Word Document
      break;
    default:
      typeValue = 1; // Default to image if the type is unrecognized
      break;
  }

  formData.append("type", typeValue);

  const config = {
    baseURL: "http://54.211.209.176:8000/admin",
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("token"),
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 50;
      console.log(`Upload progress: ${progress}%`);
    },
    onDownloadProgress: (progressEvent) => {
      const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
      console.log(`Download progress: ${progress}%`);
    },
  };

  try {
    const { data } = await Axios.post("upload-file", formData, config);
    return data.url; // Assuming the API response contains the uploaded file's path
  } catch (err) {
    console.error("Error uploading file:", err);
    return null;
  }
}

export const roundDisplay = (value) => {
  if (Number.isInteger(value)) {
    return Number(value).toLocaleString("en-US").replaceAll(",", " ");
  } else
    return Number(value)
      .toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
      .replaceAll(",", " ");
};

export const round = (num) => Math.round(num * 100) / 100;
